/* Inhalt
-------------------------------------------------*/



/*
--- Navigation Content Mobil ---*/
.gsj-menu-infos-mobil {
  ul {
    &.uk-nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      li {
        a {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 767px){
  section {
    &#tm-main-bottom {
      display: block;
    }
  }
}

@media (min-width: 768px){

}

@media (min-width: 768px) and (max-width: 1170px) {

}
