/* Header
-------------------------------------------------*/
body {
  .uk-container {
    &.uk-container-center {
      // Toolbar Links
      .tm-toolbar {
        margin-top: 5px;

        .uk-float-left {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;

          a {
            color: $blau;
          }

        }
      }
      // Navigation Intern
      nav {
        &.uk-navbar {
          background: none;

          ul {
            > li {
              a {
                border: 1px solid #ddd;
              }
            }
            li {
              &.uk-parent {
                margin-right: 10px;
                &.uk-open {
                  .uk-dropdown {
                    border: 1px solid #ddd;
                    margin-top: 10px;
                    padding-top: 0;
                    padding-bottom: 0;

                    ul {
                      li {
                        margin-left: 10px;
                        margin-right: 10px;

                        a {
                          background-color: #fff;
                          border-bottom: 1px solid #ddd;
                          color: #444;
                          padding-left: 0;
                          padding-right: 0;

                          &:hover {
                            color: #d50;
                          }
                        }
                        &:last-of-type {
                          a {
                            border-bottom: none;
                          }
                        }
                      }
                    }

                  }
                }

                ul {
                  li {
                    a {
                      border: none;
                    }
                  }
                }
              }

              &.uk-active {
                a {
                  background: none;
                  color: $blau;
                }
              }
            }
          }
        }

      }
    }
  }
}


@media (min-width: 768px) {

  body {
    .uk-container {
      &.uk-container-center {
        // Toolbar Links
        .tm-toolbar {
          margin-bottom: 0;

          .uk-float-left {
            .gsj-logo-text {
              padding-top: 50px;
              padding-bottom: 15px;
            }
          }
          .uk-float-right {
            .gsj-ameldung {
              padding-top: 30px;

              form {
                .uk-form-row {
                  margin-top: 10px;
                }
                ul {
                  &.uk-list {
                    margin-top: 10px;
                  }
                }
              }
            }
          }
        }
        nav {
          &.tm-navbar {
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }

}
