/* Generelle Angaben
-------------------------------------------------*/
html {
  background: #DEE8F0;
  height: 100%;
}
body {
  .uk-container {
    &.uk-container-center {
      background-color: #fff;
      border: 1px solid #48b;
      -webkit-box-shadow: 0px 10px 15px 8px #999;
      box-shadow: 0px 10px 15px 8px #999;
      margin-top: 25px;
    }
  }

  a {
    color: $blau;
  }
}
