/* Phoca Downloads
-------------------------------------------------*/
#tm-content {
  .pd-cb + div {
    display: none;
  }
}
#phoca-dl-category-box {
  .pd-category {
    h3 {
      &.pd-ctitle {
        background: none;
        border: none;
        font-size: 18px;
        font-weight: 600;
      }
    }

   .pd-subcategory {
      background-image: none;
      border: 1px dotted #ccc;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      float: left;
      margin-bottom: 20px;
      margin-right: 20px;
      min-height: 95px;
      position: relative;
      padding:20px;
      width: 100%;

      a {
        font-size: 18px;
        font-weight: 700;
      }

      small {
        display: block;
      }
   }

   .pd-hr-cb {
    border: none;
   }

   form {
    .pd-cb + div {
      display: block;
    }
   }

  }

  .pd-filebox {
    border-bottom: 1px dotted #ccc;
    padding: 15px 15px 25px 15px;
  }

}

#phoca-dl-categories-box {
  .pd-categoriesbox {
    border: 1px dotted #ccc;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    margin-bottom: 20px;
    margin-right: 20px;
    position: relative;
    padding-bottom: 40px;
    width: 100%;
    a {
      font-size: 18px;
      font-weight: 700;
      line-height: 40px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
@media (min-width: 768px){
  .arbeitsbereich {
    #phoca-dl-category-box {
      .pd-subcategory {
        min-height: 125px;
      }

    }
  }
  #phoca-dl-category-box {
    .pd-category {
      .pd-subcategory {
        width: 26%;
      }
    }
  }
  #phoca-dl-categories-box {
    .pd-categoriesbox {
      width: 26%;
    }
  }
}

@media (min-width: 768px) and (max-width: 1170px) {

}
